import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CataloguesService {
  url = `${environment.apiUrl}`;
  constructor(private http: HttpClient) { }

  getCatalogues() : Observable<HttpResponse<any>> {
    return this.http.get(`${this.url}/front/Catalogues/all`, { observe: 'response' });
  }

}
