<app-header></app-header>
<section>
  <div class="container text-center">
      <div class="d-flex flex-column align-items-center">
          <img class="img-fluid not-found-image" src="../../../assets/img/svg/404.svg" alt="404">
          <a class="btn btn-orange mt-5" routerLink="/">
              <img class="icon" src="../../../assets/img/svg/arrow-left-line.svg">
              <img class="icon orange" src="../../../assets/img/svg/arrow-left-line-orange.svg">
                Retour à la page d'acceuil
         </a>
      </div>
  </div>
</section>
<app-footer></app-footer>

