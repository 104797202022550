import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class VitrineService {
  constructor(private http: HttpClient) { }
  url = `${environment.apiUrl}`;
  getAboutList(){
    return this.http.get(`${this.url}/front/abouts`);
  }

  getCategorieList(){
    return this.http.get(`${this.url}/front/categories`);
  }

  getCertificateLists(){
    return this.http.get(`${this.url}/front/certificates`);
  }
  
  getFeaturesOfProduct(idProduct:number)  {
    return this.http.get(`${this.url}/product/${idProduct}/caracteristiques`);
  }

  getGammesList(){
    return this.http.get(`${this.url}/front/gammes`);
  }

  getMissionsList(){
    return this.http.get(`${this.url}/front/missions`);
  }

  getPartnersList(){
    return this.http.get(`${this.url}/front/partners`);
  }

  getProductsLists(){
    return this.http.get(`${this.url}/front/products`);
  }

  getSlidersList(){
    return this.http.get(`${this.url}/front/sliders`);
  }
  getValues(){
    return this.http.get(`${this.url}/front/values`);
  }

  getVisions(){
    return this.http.get(`${this.url}/front/visions`);
  }
  getProductById(id:any){
    return this.http.get(`${this.url}/front/product/${id}`);

  }
  createContact(formData:any){
    return this.http.post(`${this.url}/front/contact_us/create`, formData,{ observe: 'response' });
  }
  createDevis(formData:any){
    return this.http.post(`${this.url}/front/devis/create`, formData,{ observe: 'response' });
  }
  getCategorieById(id:any){
    return this.http.get(`${this.url}/front/categorie/${id}`,{ observe: 'response' });

  }

  getItemProdById(id:any){
    return this.http.post(`${this.url}/front/get_parent_product_children/${id}`,{observe: 'response'});

  }
  getPdf(id:any, name:string){
    return this.http.get(`${this.url}/front/downloadAttachment/${id}`,{ responseType: 'blob' }).subscribe((response:any) => {
      const pdfData = new Blob([response], { type: 'application/pdf' });
     saveAs(pdfData, name);
    });
  }

  getProductCarcterstiques(id:any)
  {
    return this.http.get(`${this.url}/front/product/${id}/caracteristiques`,{observe: 'response'});
  }
}
