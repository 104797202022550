import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class StorageService {
    private storage: { removeItem(key: string): void; clear(): void; length: number; getItem(key: string): (string | null); setItem(key: string, value: string): void; key(index: number): (string | null) };

    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        if (isPlatformBrowser(this.platformId)) {
            this.storage = localStorage;
        } else {
            // Fallback for non-browser platforms
            this.storage = {
                getItem(key: string): string | null {
                    return null;
                },
                setItem(key: string, value: string): void {},
                removeItem(key: string): void {},
                clear(): void {},
                length: 0,
                key(index: number): string | null {
                    return null;
                }
            };
        }
    }

    getItem(key: string): string | null {
        return this.storage.getItem(key);
    }

    setItem(key: string, value: string): void {
        this.storage.setItem(key, value);
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }

    clear(): void {
        this.storage.clear();
    }
}
