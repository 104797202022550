import { Component, Inject, PLATFORM_ID } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import { NavigationEnd, Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

declare const gtag: Function;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title(title: any) {
    throw new Error('Method not implemented.');
  }
  constructor(public router: Router,private translate: TranslateService,  @Inject(PLATFORM_ID) private platformId: Object,
  ){
    translate.addLangs(['fr'])
    translate.setDefaultLang('fr');
    translate.use('fr');
    if (isPlatformBrowser(this.platformId)) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          (window as any).gtag('config', 'G-Y37R2KXJ0F', { 'page_path': event.urlAfterRedirects });
        }
      });
    }
  }

}
