import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
@Injectable({
    providedIn: 'root'
})
export class DomService {
    public document: any = {};
    public window: any  = false;
    constructor(@Inject(PLATFORM_ID) private platformId: Object) {
        if (isPlatformBrowser(this.platformId)) {
            this.document = document;
            this.window = window;
        }
    }
    getElementById(id: string): any {
        return this.document?.getElementById(id);
    }
    getDocumentELm() {
        return this.document;
    }
    // Add other methods here as needed
    addClass(element: any, className: string): void {
      if (element && element.classList) {
          element.classList.add(className);
      }
  }

  removeClass(element: any, className: string): void {
      if (element && element.classList) {
          element.classList.remove(className);
      }
  }
  getWindowWidth(): number {
    if (this.window) {
        return this.window.innerWidth;
    } else {
        return 0; // Return a default value or handle the case where window is not available
    }
}
}
