import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  _serviceTitle = new BehaviorSubject("NOS SERVICES")
  _langueSlected = new BehaviorSubject("NOS SERVICES")
  _Products = new BehaviorSubject("NOS SERVICES")
  _categorieId = new BehaviorSubject("NOS CATEGORIE")
  _demandDevis = new BehaviorSubject("demande devis")
  _visionObservable = new BehaviorSubject([])
  _missionObservable = new BehaviorSubject([])
  _certifObservable = new BehaviorSubject([])
  _gammeObservable = new BehaviorSubject([])
  _productObservable = new BehaviorSubject([])
  _partnerObservable = new BehaviorSubject([])
  _sliderObservable = new BehaviorSubject([])
  _contactObservable = new BehaviorSubject([])
  _valueObservable = new BehaviorSubject([])
  _aboutObservable = new BehaviorSubject([])
  constructor(private location: Location) {

  }
  getServiceTitleObservable(): Observable<any> {
    return this._serviceTitle.asObservable();
  }

  setServiceTitle(name:any) {
    this._serviceTitle.next(name);
  }
  setLangueSelected(langue:any) {
    this._langueSlected.next(langue);
  }
  getLangueSelectedObservable(): Observable<any> {
    return this._langueSlected.asObservable();
  }
  setProduct(prod:any) {
    this._Products.next(prod);
  }
  getProductObservable(): Observable<any> {
    return this._Products.asObservable();
  }
  setCategorieId(id:any) {
    this._categorieId.next(id);
  }
  getCategorieIdObservable(): Observable<any> {
    return this._categorieId.asObservable();
  }
  setdemandDevis(data:any) {
    this._demandDevis.next(data);
  }
  getDemandeDevis(): Observable<any> {
    return this._demandDevis.asObservable();
  }
  getVision(){
    return this._visionObservable.value;
  }
  setVision(visions:any){
    this._visionObservable.next(visions);
  }
  getMission(){
    return this._missionObservable.value;
  }
  setMission(mission:any){
    this._missionObservable.next(mission);
  }
  getCertif(){
    return this._certifObservable.value;
  }
  setCertif(certif:any){
    this._certifObservable.next(certif);
  }
  getGamme(){
    return this._gammeObservable.value;
  }
  setGammes(gamme:any){
    this._gammeObservable.next(gamme);
  }
  getProduct(){
    return this._productObservable.value;

  }
  setProd(prod:any){
    this._productObservable.next(prod);
  }
  getPartner(){
    return this._partnerObservable.value;
  }
  setPartner(partner:any){
    this._partnerObservable.next(partner);
  }
  getSlider(){
    return this._sliderObservable.value;
  }
  setSlider(slider:any){
    this._sliderObservable.next(slider);
  }
  getContact(){
    return this._contactObservable.value;
  }
  setContact(contact:any){
    this._contactObservable.next(contact);
  }
  getValue(){
    return this._valueObservable.value;

  }
  setvalue(value:any){
    this._valueObservable.next(value);
  }
  getAbout(){
    return this._aboutObservable.value;
  }
  setAbout(about:any){
    this._aboutObservable.next(about);
  }
}
