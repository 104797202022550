import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';

import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import {AppService} from "../../_services/app.service";
import {ContactService} from "../../_services/contact.service";
import { DomService } from 'src/app/_services/dom-service.service';
import { isPlatformBrowser } from '@angular/common';
import { CataloguesService } from 'src/app/_services/catalogues.service';
declare const $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  status = false;
  $window: any = this.domService.window;

  selectItem = [
    {id:1,value:"fr",keyname:"name_fr",name:"Francais", avatar: 'assets/img/fr.png'},
    {id:2,value:"en",keyname:"name_en",name:"Anglais", avatar: 'assets/img/en.png'},
    //{id:3,value:"ar",keyname:"name_ar",name:"Arabe", avatar: 'assets/img/ar.png'}
  ];
  catalogOptions :any[]= [
  ];
  frenshCatalogues: any[]= [];
  englishCatalogues :any[]= [];
  showBrochureDropdown : boolean = false;
  currentLang !:string;
  contactList:any=[];
  constructor(private translate: TranslateService, private cataloguesService: CataloguesService,  @Inject(PLATFORM_ID) private platformId: object,private appService:AppService,private domService : DomService,private contactService:ContactService) {

  }

  ngOnInit(): void {
    this.translate.addLangs(['en', 'fr']);
    this.headerScroll();
    this.getContact();
    this.currentLang = this.translate.currentLang;
    if(!this.currentLang)
    {
      this.translate.setDefaultLang('fr');
      this.translate.use('fr');
    }
    this.getCatalogues();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });
  }

  getCatalogues()
  {
    this.cataloguesService.getCatalogues().subscribe(res=>{
      if(res.body.catalogues && res.body.catalogues.catalogues)
      {
        this.frenshCatalogues = res.body.catalogues.catalogues.fr;
        this.englishCatalogues = res.body.catalogues.catalogues.en;
        if(this.currentLang =="fr")
          this.catalogOptions=this.frenshCatalogues;
        else if(this.currentLang =="en")
          this.catalogOptions=this.englishCatalogues;
      }
   
    })
  }
  headerScroll() {
    if (isPlatformBrowser(this.platformId) && this.domService.window) {
    const containerWidth = this.domService.getWindowWidth();
      this.domService.window.addEventListener('scroll', (): void => {
        if (this.domService.window.scrollY >= 100) {
          const header = this.domService.document.querySelector('header');
          header?.classList.add('header-scrolled');
        } else {
          const header = this.domService.document.querySelector('header');
          header?.classList.remove('header-scrolled');
        }
      });
    }

  }

  clickEvent(): void {
    this.status = !this.status;
  }


  useLanguage(language: any){
    this.translate.use(language?.value);
    if(language?.value=="fr")
      this.catalogOptions=this.frenshCatalogues;
    else if(language?.value=="en")
      this.catalogOptions=this.englishCatalogues;
  }
  getContact(){
    this.contactService.getContactLists().subscribe((res:any)=>{
      this.contactList = res.contacts
    })
  }

  showPdf(pdfPath:string){
    let tab:any = window.open();
    tab.location.href = pdfPath;
  }
}
