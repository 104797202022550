// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const API_URL = "https://api.stpo.webify.pro/api"
const BASE_URL = "https://api.stp.com.tn"

export const environment = {
  production: false,
  file_path: '',
  imagePath:'https://api.stpo.webify.pro/public/avatars/',
  apiUrl: API_URL,
  baseUrl: BASE_URL,
  jsonUrl:"http://localhost:4200/assets/json/data.json"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
