import { Component, OnInit } from '@angular/core';
import {AppService} from "../../_services/app.service";
import {ContactService} from "../../_services/contact.service";
import {VitrineService} from "../../_services/vitrine.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  showLangFr:boolean = true;
  showLangAr:boolean = false;
  showLangEn:boolean = false;
  langueSelected  : any;
  contactList:any=[];
  gammeList:any=[];
  constructor(private appService:AppService,private contactService:ContactService,private vitrineService:VitrineService,public router: Router) { }

  ngOnInit(): void {
    this.appService.getLangueSelectedObservable().subscribe((res:any)=>{
      this.langueSelected = res
      switch (this.langueSelected ) {
        case "fr":
          this.showLangFr = true;
          this.showLangEn = false;
          this.showLangAr = false
          break;
        case "ar":
          this.showLangFr =false;
          this.showLangEn = false;
          this.showLangAr = true
          break;
        case "en":
          this.showLangFr =false;

          this.showLangEn = true;
          this.showLangAr = false
          break;
        default:
          break;
      }
    }) ;
    this.getContact();
    this.getGammeList()
  }
  getContact(){
    this.contactService.getContactLists().subscribe((res:any)=>{
      this.contactList = res.contacts
    })
  }
  getGammeList(){
    this.vitrineService.getCategorieList().subscribe((res:any)=>{
      this.gammeList = res?.categorys;
    })
  }
  goToCategorie(id:any){
    this.appService.setCategorieId(id);
    this.router.navigate(['/nos-services/nos-categorie'])
  }
}
