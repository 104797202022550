import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { retry, catchError } from 'rxjs/operators';
import { Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import jwt_decode from "jwt-decode";

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  url = `${environment.apiUrl}`;
  private isAuthenticated = false;
  private token: string | undefined;
  private authStatusListener = new Subject<boolean>();
  private isresetPasswordListener = new Subject<boolean>();
  private isNewPasswordListener = new Subject<boolean>();

  constructor(private http: HttpClient,private router : Router) { }
  /*==============================
   Create contact
  ================================*/
   createContact(formData:any) {
     return this.http.post (this.url +'/front/contact_us/create',formData, { observe: 'response' })

   }

  getContactLists(){
    return this.http.get(`${this.url}/front/contacts`);
  }
}
