import { Component, Inject, Optional } from '@angular/core';
import { RESPONSE } from '@nguniversal/express-engine/tokens';
import { Response } from 'express';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent {
private response: Response;
constructor(@Optional() @Inject(RESPONSE) response: any)
{
  this.response = response;
}

ngOnInit() {
  if (this.response) {
    this.response.status(404);
  }
}
}

