<footer>
  <div class="container">
    <div class="grid gtc-7 gcg-3">
      <div class="grid-blc headerblc1">
        <a class="footer-logo" routerLink="/" >
          <img src="../../../assets/images/logo-stpo-white.png" alt="logo-stpo">
        </a>
        <div class="footer-description">
          <p>
            {{'FOOTER.STP' | translate}}
          </p>
          <p>
            <strong>
              {{'FOOTER.HOURS' | translate}}</strong> {{'FOOTER.MON_FRI' | translate}}
            - {{'FOOTER.SATURDAY' | translate}}
          </p>
        </div><!--.footer-description-->
      </div><!--.grid-blc-->
      <div class="grid-blc headerblc2">
        <h4 class="footer-list-title">{{'FOOTER.JOIN' | translate}}</h4>
        <div class="informations-contact-list">
          <ul>
            <li>
              <div class="info-contact-item our-location-icn">
                <p *ngIf="showLangFr">
                  {{contactList[0]?.address_fr}}
                </p>
                <p *ngIf="showLangAr">
                  {{contactList[0]?.address_ar}}
                </p>
                <p *ngIf="showLangEn">
                  {{contactList[0]?.address_en}}
                </p>
              </div>
            </li>
            <li>
              <div class="info-contact-item mobile-icn">
                {{contactList[0]?.tel_1}}
              </div>
            </li>
            <li>
              <div class="info-contact-item email-icn">
                <a href="mailto:contact@stpo.com.tn"></a>
                {{contactList[0]?.email}}
              </div>
            </li>
          </ul>
        </div>
      </div><!--.grid-blc-->
      <div class="grid-blc headerblc3">
        <h4 class="footer-list-title">{{'FOOTER.SERVICE' | translate}}</h4>
        <div class="our-services-list">
          <ul>
            <li *ngFor="let categorie of  gammeList">
              <a class="our-services-item" *ngIf="showLangAr" (click)="goToCategorie(categorie.id)">{{categorie.name_ar}}</a>
              <a class="our-services-item" *ngIf="showLangFr" (click)="goToCategorie(categorie.id)">{{categorie.name_fr}}</a>
              <a class="our-services-item" *ngIf="showLangEn" (click)="goToCategorie(categorie.id)">{{categorie.name_en}}</a>
            </li>
          </ul>
        </div>
      </div><!--.grid-blc-->
     <!-- <div class="grid-blc">
        <h4 class="footer-list-title">{{'FOOTER.NEWSLETTER' | translate}}</h4>
        <div class="footer-description">
          <p>
            {{'FOOTER.SUBSCRIBE' | translate}}
          </p>
        </div>
        <div class="news-letter-blc form-wrapper">
          <div class="news-letter-input">
            <input type="text" class="form-control form-style-1" placeholder="{{'FOOTER.ADRESS_MAIL' | translate}}">
          </div>
          <div class="btns-group">
            <button class="btn btn-orange">{{'FOOTER.SUBSCRIBE_NOW' | translate}}</button>
          </div>
        </div>
      </div>.grid-blc-->
        </div>
        <!--.grid-->
    </div>
    <!--.container-->
</footer>
